<template>
  <div>
    <div id="puzzle">
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageJigsawInput',
  data () {
    return {
      pieces: [],
      selectedIndex: null,
      selectedIndex2: null
    }
  },
  props: {
    imageUrl: {
      type: String,
      default: 'https://images.unsplash.com/photo-1678156466282-ed9f0a9f1845?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80'
    },
    noOfXPieces: {
      type: Number,
      default: 2
    },
    noOfYPieces: { // ignored for now, since there is a bug with different pieces.
      type: Number,
      default: 2
    }
  },
  methods: {
    onPieceClick (event) {
      if (this.selectedIndex === null) {
        this.selectedIndex = event.target.id
        event.target.classList.add('selected-piece')
      } else if (this.selectedIndex === event.target.id) {
        this.selectedIndex = null
        event.target.classList.remove('selected-piece')
      } else {
        this.selectedIndex2 = event.target.id
      }

      if (this.selectedIndex !== null && this.selectedIndex2 !== null) {
        const temp = this.pieces[this.selectedIndex]
        this.pieces[this.selectedIndex] = this.pieces[this.selectedIndex2]
        this.pieces[this.selectedIndex2] = temp
        this.selectedIndex = null
        this.selectedIndex2 = null

        var puzzle = document.getElementById("puzzle")
        while (puzzle.firstChild) {
          puzzle.removeChild(puzzle.firstChild);
        }
        for (let i = 0; i < this.pieces.length; i++) {
          this.pieces[i].setAttribute("id", i)
          this.pieces[i].classList.remove('selected-piece')
          puzzle.appendChild(this.pieces[i])
        }

        let inOrder = true
        for (let i = 0; i < this.pieces.length; i++) {
          if (
            i !== this.pieces.length-1 &&
            parseInt(this.pieces[i].getAttribute("name")) > parseInt(this.pieces[i+1].getAttribute("name"))
          ) {
            inOrder = false
          }

          if (
            parseInt(this.pieces[i].getAttribute("name")) === i
          ) {
            this.pieces[i].classList.remove('selected-piece')
            this.pieces[i].classList.add('correct-piece')
          } else {
            this.pieces[i].classList.remove('correct-piece')
          }
        }
        if (inOrder) {
          this.$emit('imageJigsawSolved')
        }
      }
    }
  },
  mounted () {
    // Get the image URL
    // var imageUrl = prompt("Please enter the image URL")
    var imageUrl = this.imageUrl
    // Create an image element
    var image = document.createElement("img")
    // Set the image source
    image.src = imageUrl
    // Create an array to store the pieces
    // When the image is loaded
    image.onload = () => {
      // Calculate the size of each piece
      var pieceWidth = image.width / this.noOfXPieces
      var pieceHeight = image.height / this.noOfXPieces
      // Create 16 canvas elements
      const noOfPieces = this.noOfXPieces * this.noOfXPieces
      for (var i = 0; i < noOfPieces; i++) {
        // Create a new piece
        var piece = document.createElement("canvas")
        // Set the size of the piece
        piece.width = pieceWidth
        piece.height = pieceHeight
        // Set the position of the piece
        piece.style.left = (i % this.noOfXPieces * pieceWidth) + "px"
        piece.style.top = (Math.floor(i / this.noOfXPieces) * pieceHeight) + "px"
        piece.style.borderStyle = 'groove'
        piece.style.borderColor = 'grey'

        piece.style.width = 100/this.noOfXPieces + '%'

        piece.setAttribute("name", i)
        piece.addEventListener("click", this.onPieceClick)
        // Get the context of the piece
        var context = piece.getContext("2d")
        // Draw the piece
        context.drawImage(image,
          (i % this.noOfXPieces * pieceWidth), (Math.floor(i / this.noOfXPieces) * pieceHeight),
          pieceWidth, pieceHeight,
          0, 0,
          pieceWidth, pieceHeight)
        // Add the piece to the pieces array
        this.pieces.push(piece)
      }

        // Get the puzzle div
        var puzzle = document.getElementById("puzzle")
        this.pieces.sort(() => Math.random() - 0.5)
        // Add the piece to the puzzle
        this.pieces.forEach((piece, i) => {
          piece.setAttribute("id", i)
          puzzle.appendChild(piece)
        })

        for (let i = 0; i < this.pieces.length; i++) {
          if (
            parseInt(this.pieces[i].getAttribute("name")) === i
          ) {
            this.pieces[i].classList.remove('selected-piece')
            this.pieces[i].classList.add('correct-piece')
          } else {
            this.pieces[i].classList.remove('correct-piece')
          }
        }
    }
  }
}
</script>

<style>
#puzzle {
  width: 100%;
  /* height: 400px; */
  position: relative;

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
/* .piece {
  width: 25%;
  height: 25%;
  position: absolute;
} */


.selected-piece {
  border-style: solid !important;
  border-color: #fffb00 !important;
}

.correct-piece {
  border-style: solid !important;
  border-color: #37ff00 !important;
}
</style>
