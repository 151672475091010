<template>
  <div class="container">
    <FormInput :size="'medium'" :type="'text'" :value="code" v-on:update="code = $event"/>
    <div @click="$router.push({ name: 'Inventory' })" v-if="showItemFoundMessage" class="container has-text-centered is-clickable mt-2">
      <b>Something found!</b> Go to your <i class="fas fa-sm fa-box ml-1"></i> Inventory to see it.
    </div>
    <div v-if="showItemNotFoundMessage" class="container has-text-centered my-2">
      Nothing found, try another code.
    </div>
    <div class="container has-text-centered">
      <a
      @click="scavenge()"
      class="button has-text-weight-semibold is-large is-primary mt-2"
      v-bind:class="{'is-loading': loading}"
      :disabled="loading"
      >
        Enter
      </a>
    </div>
  </div>
</template>

<script>
import firebaseApp from '@/firebase/init'
import FormInput from '@/components/forms/FormInput'

export default {
  name: 'CodeInput',
  components: {
    FormInput
  },
  data () {
    return {
      code: null,
      loading: false,
      showItemNotFoundMessage: false,
      showItemFoundMessage: false
    }
  },
  watch: {
    code() {
      this.showItemFoundMessage = false
      this.showItemNotFoundMessage = false
    }
  },
  methods: {
    scavenge () {
      if(!this.loading){
        this.loading = true
        const masterFunction = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunction')
        masterFunction({
          methodName: 'search',
          teamCode: this.$store.state.teamCode,
          userName: this.$store.state.userName,
          scanCode: this.code,
          position: null
        }).then(res => {
          this.loading = false
          if(res.data.foundItems.length > 0) {
            this.showItemFoundMessage = true
          } else {
            if(res.data.foundItems.length === 0) {
              this.showItemNotFoundMessage = true
            }
          }
        }).catch(err => {
          this.loading = false
        })
      }
    }
  }
}
</script>

<style>

</style>
