<template>
  <div>
    <div class="modal is-active">
      <div @click="close()" class="modal-background"></div>
      <div class="modal-card has-rounded-corners result-modal">
          <section class="modal-card-body">
            <div class="card-content p-0">
              <div class="container is-size-4 has-text-weight-semibold has-text-centered has-baskerville-font has-text-brand-color mb-2">
                {{ $store.state.uiMods.chapter }} completed!
              </div>
              <div class="container has-text-centered mb-4">
                {{ this.$store.state.adventureTeam.teamMembers.length === 1 ? 'You have' : 'Your team has' }} completed the {{ $store.state.uiMods.chapter.toLowerCase() }}.
              </div>
                <div @click="close()" class="button is-primary-colors has-text-weight-semibold is-fullwidth">
                Go to next {{ $store.state.uiMods.chapter.toLowerCase() }}
                </div>
            </div>
          </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NextStage',
  methods: {
    close() {
      window.scrollTo(0,0)
      this.$store.commit('updateShowNextStageAlert', false)
      this.$store.commit('updateCurrentStage', this.adventureTeam.stage)
      this.$store.commit('updateTaskCategoryFilter', null)
      this.$router.push({ name: 'Stage' })
    }
  },
  computed: {
    adventureTeam(){
      return this.$store.state.adventureTeam
    }
  },
  mounted () {
    if (this.adventureTeam && this.adventureTeam.uiMods.skipNextChapterPopup) {
      this.close()
    }
  }
}
</script>

<style scoped>
</style>
