<template>
  <div class="mb-3 is-flex is-justify-content-center is-flex-wrap-wrap">
    <button
      @click="handleSelectedIndex(index)"
      v-for="(position, index) in shownOrder"
      :key="index"
      class="notranslate button is-mobile mb-1 mr-2"
      :class="{ 'is-primary': index === selectedIndex }"
    >
    {{ textsArray[position] }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'ScrambledPhraseInput',
  props: {
    text: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      textsArray: [],
      shownOrder: [],
      selectedIndex: null,
      selectedIndex2: null
    }
  },
  methods: {
    handleSelectedIndex (index) {
      if (this.selectedIndex === null) {
        this.selectedIndex = index
      } else if (this.selectedIndex === index) {
        this.selectedIndex = null
      } else {
        this.selectedIndex2 = index
      }

      if (this.selectedIndex !== null && this.selectedIndex2 !== null) {
        const temp = this.shownOrder[this.selectedIndex]
        this.shownOrder[this.selectedIndex] = this.shownOrder[this.selectedIndex2]
        this.shownOrder[this.selectedIndex2] = temp
        this.selectedIndex = null
        this.selectedIndex2 = null

        this.updateAnswer()
      }
    },
    updateAnswer () {
      const answer = []
      this.shownOrder.forEach((order) => {
        answer.push(this.textsArray[order])
      })
      this.$emit('update', answer.join(' '))
    }
  },
  mounted () {
    this.textsArray = this.text.split(' ')

    this.textsArray.forEach((word, index) => {
      this.shownOrder.push(index)
    })

    this.shownOrder.sort(() => {
      return 0.5 - Math.random()
    })

    this.updateAnswer()
  }
}
</script>

<style>
.selected-box {
  background-color: rgb(142, 142, 142);
}
</style>
