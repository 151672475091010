<template>
  <div class="modal is-active" style="padding-top: 1.25rem; justify-content: flex-start;">
    <div class="modal-background pointer" @click="close"></div>
    <div class="modal-card" style="padding-left: 0.75rem; padding-right: 0.75rem; max-height: calc(100vh - 88px);">
      <section class="modal-card-body has-rounded-corners">
        <div class="container has-text-centered" style="padding-top: 0.5rem; margin-bottom: 0.75rem; display: flex; flex-direction: column; height: 70vh;">
          <div class="is-size-5 has-text-weight-semibold has-baskerville-font has-text-brand-color" style="flex-shrink: 0;">
            I completed this with...
          </div>
          <div style="margin-bottom: 1.25rem;">
            You must select {{ noOfTeamsToSelect }} {{ noOfTeamsToSelect === 1 ? 'name' : 'names' }}.
          </div>
          <div class="tags" style="overflow-y: auto; flex-grow: 1;">
            <div v-for="(rank, index) in sortedRanking" :key="index"
                class="tag is-medium pointer"
                :class="{'is-primary': selectedTeams.includes(rank.teamName)}"
                @click="selectTeam(rank.teamName)">
              {{ rank.teamName }}
            </div>
          </div>
          <button
          class="button is-fullwidth is-primary" style="margin-top: auto;" :disabled="selectedTeams.length !== noOfTeamsToSelect"
          @click="submitSelection">
            Confirm
          </button>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import firebaseApp from '@/firebase/init'

const leaderboards = firebaseApp.firestore().collection('leaderboards')

export default {
  name: 'SelectTeamNameModal',
  props: ['noOfTeamsToSelect'],
  data () {
    return {
      leaderboard: null,
      selectedTeams: []
    }
  },
  computed: {
    adventureTeam() {
      return this.$store.state.adventureTeam
    },
    ranking(){
      if (this.leaderboard && this.leaderboard.ranking) {
        return this.leaderboard.ranking.filter(el => {
          return el.sessionId === this.adventureTeam.sessionId && this.adventureTeam.teamName !== el.teamName
        })
      } else {
        return []
      }
    },
    sortedRanking() {
      return [...this.ranking].sort((a, b) => a.teamName.localeCompare(b.teamName));
    }
  },
  methods: {
    selectTeam(teamName) {
      const index = this.selectedTeams.indexOf(teamName);
      if (index > -1) {
        this.selectedTeams.splice(index, 1);
      } else {
        this.selectedTeams.push(teamName);
      }
    },
    submitSelection() {
      if (this.selectedTeams.length === this.noOfTeamsToSelect) {
        this.$emit('submitAnswer', this.selectedTeams);
      }
    },
    close() {
      this.$emit('close')
    }
  },
  watch: {
    adventureTeam: {
      immediate: true,
      handler(adventureTeam) {
        if(adventureTeam && adventureTeam.hideLeaderboard !== true){
          this.$bind('leaderboard', leaderboards.doc(adventureTeam.adventureId))
        }
      }
    }
  },
}
</script>
