<template>
  <div>
    <div class="is-flex is-flex-wrap-wrap is-justify-content-center">
      <div
        v-for="(word, index) in words"
        :key="index"
        class="mr-1"
      >
        <div v-if="!word.includes('{')" class="notranslate">
          {{ word }}
        </div>
        <div v-else class="is-flex is-flex-wrap-wrap">
          <div class="notranslate">
            {{ getSubstringBeforeDollarAndCurly(word) }}
          </div>
          <FormInput
            :size="'small'"
            :title="''"
            :type="'text'"
            :value="answers[index]"
            :centered="true"
            v-on:update="updateAnswers($event, index)"
          />
          <div class="notranslate">
            {{ getSubstringAfterCurly(word) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormInput from '@/components/forms/FormInput'

export default {
  name: 'FillBlanksInput',
  props: {
    text: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      answers: {}
    }
  },
  components: {
    FormInput
  },
  computed: {
    words () {
      return this.text.split(' ')
    }
  },
  methods: {
    updateAnswers(str, index) {
      this.answers[index] = str
      console.log(this.mergeWordsWithAnswers())
      this.$emit('update', this.mergeWordsWithAnswers())
    },
    getSubstringBeforeDollarAndCurly(str) {
      let result = '';
      for (let i = 0; i < str.length; i++) {
        if (str[i] === '{') {
          break;
        }
        result += str[i]
      }
      return result
    },
    getSubstringAfterCurly(str) {
      return str.substring(str.indexOf('}') + 1)
    },
    mergeWordsWithAnswers() {
      const answersList = Object.keys(this.answers).map(key => this.answers[key])
      const newWords = this.words.map(word => {
        if (word.includes('{}') && answersList.length > 0) {
          word = word.replace('{}', answersList.shift())
          return word
        }
        return word
      })
      return newWords.join(' ')
    }
  }
}
</script>

<style>

</style>
