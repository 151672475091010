import { render, staticRenderFns } from "./FillBlanksInput.vue?vue&type=template&id=04ac10da"
import script from "./FillBlanksInput.vue?vue&type=script&lang=js"
export * from "./FillBlanksInput.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports