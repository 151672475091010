<template>
  <div v-if="slots && selectedSlots" class="mb-3">
    <div class="is-flex is-flex-wrap-wrap is-justify-content-center">
      <div
        v-for="(slot, index) in slots"
        :key="index"
        class="is-flex is-flex-direction-column is-align-items-center px-1"
      >
        <div @click="changeSlot(index, 1)">
          <i class="fas is-size-4 fa-caret-up is-clickable"></i>
        </div>
        <div @click="changeSlot(index, 1)" class="notranslate is-size-5 tag combination-key">
        {{ slot[selectedSlots[index]] }}
        </div>
        <div @click="changeSlot(index, -1)">
          <i class="fas is-size-4 fa-caret-down is-clickable"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CombinationLockInput',
  props: {
    config: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      slots: null,
      selectedSlots: null,
      answers: {}
    }
  },
  mounted () {
    this.slots = this.getSlots(this.config)
    this.selectedSlots = this.getSelectedSlots(this.getSlots(this.config))
    this.updateAnswer()
  },
  methods: {
    getSlots (str) {
      const array = str.slice(1, -1) // remove outer parentheses
        .split(")(")  // split by each set of parentheses
        .map((set) => set.split(",")); // split each set by commas

      return array
    },
    getSelectedSlots (mainArr) {
      const randomIndexes = []

      for (const subArr of mainArr) {
        const randomIndex = Math.floor(Math.random() * subArr.length)
        randomIndexes.push(randomIndex)
      }

      return randomIndexes
    },
    changeSlot (index, change) {
      const max = this.slots[index].length
      const newVal = this.selectedSlots[index] + change
      if (newVal < 0) {
        this.selectedSlots[index] = max - 1
      } else if (newVal === max) {
        this.selectedSlots[index] = 0
      } else {
        this.selectedSlots[index] = newVal
      }
      this.selectedSlots = [...this.selectedSlots]
      this.updateAnswer()
    },
    updateAnswer () {
      let answer = ''
      this.selectedSlots.forEach((x, index) => {
        answer += this.slots[index][x]
      })
      this.$emit('update', answer)
    }
  }
}
</script>

<style>
.combination-key {
}
</style>
