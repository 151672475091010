<template>
  <div>
    <div v-for="(value, index) in values" :key="value.key" class="mb-2">
      <div v-if="index === values.length - 1" class="has-text-centered has-text-grey-light is-size-7">
        You can upload multiple images - upload 1 at a time
      </div>
      <FormInput
      :index="value.key"
      :isAnother="index > 0"
      :size="'medium'"
      :title="''"
      type="image"
      :value="value.value"
      v-on:update="value.value = $event"
      />
    </div>
    <div v-if="isNewUploadBlocked" class="has-text-centered mt-3">
      <i class="fas fa-spinner fa-spin"></i> Uploading...
    </div>
  </div>
</template>

<script>
import firebaseApp from '@/firebase/init'
import FormInput from '@/components/forms/FormInput'
import { compressImage } from '@/utils/utils.ts'

export default {
  name: 'MultiFormInput',
  components: {
    FormInput
  },
  data() {
    return {
      values: [{ value: null, key: Date.now() }],
      valuesLength: 1,
      isNewUploadBlocked: false
    }
  },
  methods: {
    async processUpload () {
      // @ts-ignore
      for (let value of this.values) {
        if (value.value && value.value.image) {
          const compressedImage = await compressImage(value.value.image)
          const filename = Date.now().toString() + '.webp'
          await firebaseApp.storage()
          .ref(`/judgementFiles/${this.$store.state.teamCode}/${filename}`)
          .put(compressedImage)
          .then(() => {
            return firebaseApp.storage()
            .ref(`/judgementFiles/${this.$store.state.teamCode}/${filename}`)
            .getDownloadURL().then(downloadURL => {
              value.value = downloadURL
            })
          })
        }
      }

      const arrayToEmit = []
      for (let value of this.values) {
        if (value.value && value.value.includes('https://')) arrayToEmit.push(value.value)
      }
      this.$emit('update', arrayToEmit)
      this.isNewUploadBlocked = false
    }
  },
  async mounted () {
    setInterval(async () => {
      const notUploadedCount = this.values.filter(value => value.value && value.value.image).length
      if (notUploadedCount > 0 && !this.isNewUploadBlocked) {
        this.$emit('update', null) // to prevent early submission in challenge.vue
        this.isNewUploadBlocked = true
        await this.processUpload()
      }

      const lastItem = this.values[this.values.length - 1]
      if (lastItem.value !== null) {
        this.values = [...this.values, { value: null, key: Date.now() }]
      }
      this.values = this.values.filter((value, index) => {
        if (value.value === null && index <= this.values.length - 2) return false
        return true
      })
    }, 100)
  }
}
</script>
